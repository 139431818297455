import { useApiClient } from "@/composables/useApiClient";
import { useUrlsStore } from "@/stores/urls";

export const useOauthIframe = () => {
  const userStore = useUserStore();
  const urlsStore = useUrlsStore();

  const router = useRouter();
  const route = useRoute();

  const hasPrintToken = () =>
    new URLSearchParams(window.location.search).has("print_token");

  const hasOauthError = computed(() => {
    return !!route.query.oauth_error;
  });

  const { sessions: sessionsService } = useApiClient();

  const handleLogin = () => {
    if (
      userStore.isAuthenticated ||
      userStore.isPupil ||
      userStore.isVisitor ||
      route.name === "login" ||
      hasPrintToken() ||
      hasOauthError.value
    ) {
      return;
    }

    console.info("OAuth Iframe: Handle Login...");

    router.push({
      name: "login",
      query: {
        redirectUri: window.location.href,
      },
    });
  };

  const handleLogout = async () => {
    if (
      !userStore.isAuthenticated ||
      userStore.isPupil ||
      userStore.isVisitor ||
      hasPrintToken() ||
      hasOauthError.value
    ) {
      return;
    }

    console.info("OAuth Iframe: Handle Logout...");

    await sessionsService.deleteSession();
  };

  const handleEvent = (event: MessageEvent) => {
    if (
      !["home", "partners-index", "partners-show"].includes(String(route.name))
    ) {
      return;
    }

    if (event.data === "auth") {
      setTimeout(() => {
        handleLogin();
      }, 500);
    }

    if (event.data === "logout") {
      handleLogout();
    }
  };

  const iframe = ref<HTMLIFrameElement>();

  onMounted(() => {
    setupIframe();

    window.addEventListener("message", handleEvent, false);
    window.addEventListener("focus", refreshIframe, false);
  });

  onUnmounted(() => {
    window.removeEventListener("message", handleEvent);
    window.removeEventListener("focus", refreshIframe);

    if (iframe.value) {
      document.body.removeChild(iframe.value);
    }
  });

  const setupIframe = () => {
    iframe.value = document.createElement("iframe");
    iframe.value.id = "oauth-iframe";
    iframe.value.src = urlsStore.oauthEmbedUrl;
    iframe.value.style.display = "none";
    document.body.appendChild(iframe.value);
  };

  const refreshIframe = () => {
    console.info("OAuth Iframe: Refreshing...");
    if (iframe.value) {
      iframe.value.src = urlsStore.oauthEmbedUrl;
    } else {
      setupIframe();
    }
  };
};
