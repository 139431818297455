/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PaginatedProjectItems } from '../models/PaginatedProjectItems';
import type { PaginatedProjects } from '../models/PaginatedProjects';
import type { PaginatedSchoolClasses } from '../models/PaginatedSchoolClasses';
import type { Project } from '../models/Project';
import type { ProjectInput } from '../models/ProjectInput';
import type { ProjectItem } from '../models/ProjectItem';
import type { ProjectItemInput } from '../models/ProjectItemInput';
import type { ProjectResumeInput } from '../models/ProjectResumeInput';
import type { SchoolClass } from '../models/SchoolClass';
import type { SchoolClassCreateInput } from '../models/SchoolClassCreateInput';
import type { SchoolClassJoinInput } from '../models/SchoolClassJoinInput';
import type { SchoolClassUpdateInput } from '../models/SchoolClassUpdateInput';
import type { User } from '../models/User';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SchoolClassesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create SchoolClass
     * @returns SchoolClass successful - onetime
     * @throws ApiError
     */
    public createSchoolClass({
        requestBody,
    }: {
        requestBody: {
            schoolClass?: SchoolClassCreateInput;
        },
    }): CancelablePromise<SchoolClass> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/school_classes',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `bad request`,
            },
        });
    }
    /**
     * List of SchoolClasses
     * @returns PaginatedSchoolClasses successful
     * @throws ApiError
     */
    public listSchoolClasses({
        page = 1,
        limit = 10,
        discarded = 'false',
    }: {
        page?: number,
        limit?: number,
        discarded?: string,
    }): CancelablePromise<PaginatedSchoolClasses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/school_classes',
            query: {
                'page': page,
                'limit': limit,
                'discarded': discarded,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete SchoolClass
     * @returns void
     * @throws ApiError
     */
    public deleteSchoolClass({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/school_classes/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * SchoolClass Details
     * @returns SchoolClass successful
     * @throws ApiError
     */
    public getSchoolClass({
        id,
    }: {
        id: string,
    }): CancelablePromise<SchoolClass> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/school_classes/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update SchoolClass
     * @returns SchoolClass successful
     * @throws ApiError
     */
    public updateSchoolClass({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            schoolClass?: SchoolClassUpdateInput;
        },
    }): CancelablePromise<SchoolClass> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/school_classes/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Join SchoolClass
     * @returns User successful
     * @throws ApiError
     */
    public joinSchoolClass({
        requestBody,
    }: {
        requestBody: SchoolClassJoinInput,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/school_classes/join',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `already signed in`,
                404: `not found`,
                422: `max_pupils_reached`,
            },
        });
    }
    /**
     * Create Project
     * @returns Project successful
     * @throws ApiError
     */
    public createProject({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            project?: ProjectInput;
        },
    }): CancelablePromise<Project> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/school_classes/{id}/projects',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                422: `not allowed for onetime classes`,
            },
        });
    }
    /**
     * List of Projects
     * @returns PaginatedProjects successful - filtered discarded
     * @throws ApiError
     */
    public listProjects({
        id,
        page = 1,
        limit = 10,
        discarded = false,
    }: {
        id: string,
        page?: number,
        limit?: number,
        discarded?: boolean,
    }): CancelablePromise<PaginatedProjects> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/school_classes/{id}/projects',
            path: {
                'id': id,
            },
            query: {
                'page': page,
                'limit': limit,
                'discarded': discarded,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete Project
     * @returns void
     * @throws ApiError
     */
    public deleteProject({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/projects/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
                422: `not allowed for onetime classes`,
            },
        });
    }
    /**
     * Project Details
     * @returns Project successful
     * @throws ApiError
     */
    public getProject({
        id,
    }: {
        id: string,
    }): CancelablePromise<Project> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Project
     * @returns any successful
     * @throws ApiError
     */
    public updateProject({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            project?: ProjectInput;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/projects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Finish Project
     * @returns any successful
     * @throws ApiError
     */
    public finishProject({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/projects/{id}/finish',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create ProjectItem
     * @returns ProjectItem successful
     * @throws ApiError
     */
    public createProjectItem({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: ProjectItemInput,
    }): CancelablePromise<ProjectItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/projects/{id}/items',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `dont allow to use other users items`,
                401: `unauthorized`,
                403: `forbidden for other user`,
                404: `not found`,
            },
        });
    }
    /**
     * List ProjectItems
     * @returns PaginatedProjectItems successful
     * @throws ApiError
     */
    public listProjectItems({
        id,
        page = 1,
        limit = 10,
    }: {
        id: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PaginatedProjectItems> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/projects/{id}/items',
            path: {
                'id': id,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Delete ProjectItem
     * @returns void
     * @throws ApiError
     */
    public deleteProjectItem({
        projectId,
        id,
    }: {
        projectId: string,
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/projects/{projectId}/items/{id}',
            path: {
                'projectId': projectId,
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Pause Project
     * @returns any successful
     * @throws ApiError
     */
    public pauseProject({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/projects/{id}/pause',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Resume Project
     * @returns any successful
     * @throws ApiError
     */
    public resumeProject({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            project?: ProjectResumeInput;
        },
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/projects/{id}/resume',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Pupil
     * @returns User successful
     * @throws ApiError
     */
    public createPupil({
        id,
    }: {
        id: string,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/school_classes/{id}/pupils',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `bad request`,
            },
        });
    }
    /**
     * List of Pupils
     * @returns User successful
     * @throws ApiError
     */
    public listPupils({
        id,
    }: {
        id: string,
    }): CancelablePromise<Array<User>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/school_classes/{id}/pupils',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Deactivate Pupil
     * @returns void
     * @throws ApiError
     */
    public deactivatePupil({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/pupils/{id}/deactivate',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Impersonate Pupil
     * @returns void
     * @throws ApiError
     */
    public impersonatePupil({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/pupils/{id}/impersonate',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Pupil Details
     * @returns User successful
     * @throws ApiError
     */
    public getPupil({
        id,
    }: {
        id: string,
    }): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupils/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
}
