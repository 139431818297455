/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AiAssistant } from '../models/AiAssistant';
import type { AiAssistantInput } from '../models/AiAssistantInput';
import type { PaginatedAiAssistants } from '../models/PaginatedAiAssistants';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AiAssistantsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Archive AI Assistant
     * @returns void
     * @throws ApiError
     */
    public archiveAiAssistant({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/assistants/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create AI Assistant
     * @returns AiAssistant successful
     * @throws ApiError
     */
    public createAssistant({
        requestBody,
    }: {
        requestBody: {
            assistant?: AiAssistantInput;
        },
    }): CancelablePromise<AiAssistant> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/ai/assistants',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `unprocessable entity`,
            },
        });
    }
    /**
     * List of AI Assistants
     * @returns PaginatedAiAssistants successful
     * @throws ApiError
     */
    public listAiAssistants({
        page = 1,
        limit = 10,
        query,
        category,
    }: {
        page?: number,
        limit?: number,
        query?: string,
        category?: string,
    }): CancelablePromise<PaginatedAiAssistants> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/assistants',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
                'category': category,
            },
        });
    }
    /**
     * Delete AI Assistant
     * @returns void
     * @throws ApiError
     */
    public deleteAiAssistant({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/ai/assistants/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * AI Assistant Details
     * @returns AiAssistant successful
     * @throws ApiError
     */
    public getAiAssistant({
        id,
    }: {
        id: string,
    }): CancelablePromise<AiAssistant> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/assistants/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update AI Assistant
     * @returns AiAssistant successful
     * @throws ApiError
     */
    public updateAiAssistant({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            assistant?: AiAssistantInput;
        },
    }): CancelablePromise<AiAssistant> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/ai/assistants/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Recently used AI Assistants
     * @returns PaginatedAiAssistants successful
     * @throws ApiError
     */
    public recentlyUsedAiAssistants({
        page = 1,
        limit = 10,
        query,
    }: {
        page?: number,
        limit?: number,
        query?: string,
    }): CancelablePromise<PaginatedAiAssistants> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/assistants/recently_used',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
            },
            errors: {
                401: `unauthorized`,
            },
        });
    }
    /**
     * Search AI Assistants
     * @returns PaginatedAiAssistants pupils filter
     * @throws ApiError
     */
    public searchAiAssistants({
        page = 1,
        limit = 10,
        query,
        category,
        pupils,
        international,
    }: {
        page?: number,
        limit?: number,
        query?: string,
        category?: string,
        pupils?: boolean,
        international?: string,
    }): CancelablePromise<PaginatedAiAssistants> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/ai/assistants/search',
            query: {
                'page': page,
                'limit': limit,
                'query': query,
                'category': category,
                'pupils': pupils,
                'international': international,
            },
        });
    }
}
