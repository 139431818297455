/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Worksheet } from '../models/Worksheet';
import type { WorksheetAnswerOption } from '../models/WorksheetAnswerOption';
import type { WorksheetAnswerOptionInput } from '../models/WorksheetAnswerOptionInput';
import type { WorksheetBlock } from '../models/WorksheetBlock';
import type { WorksheetBlockInput } from '../models/WorksheetBlockInput';
import type { WorksheetBlocks } from '../models/WorksheetBlocks';
import type { WorksheetBlockSolution } from '../models/WorksheetBlockSolution';
import type { WorksheetBlockSolutionInput } from '../models/WorksheetBlockSolutionInput';
import type { WorksheetInput } from '../models/WorksheetInput';
import type { WorksheetResponse } from '../models/WorksheetResponse';
import type { WorksheetResponseFeedback } from '../models/WorksheetResponseFeedback';
import type { WorksheetResponseFeedbackInput } from '../models/WorksheetResponseFeedbackInput';
import type { WorksheetResponseInput } from '../models/WorksheetResponseInput';
import type { WorksheetResponses } from '../models/WorksheetResponses';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WorksheetsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Answer Option
     * @returns WorksheetAnswerOption successful
     * @throws ApiError
     */
    public createAnswerOption({
        blockId,
        requestBody,
    }: {
        blockId: string,
        requestBody: {
            answerOption?: WorksheetAnswerOptionInput;
        },
    }): CancelablePromise<WorksheetAnswerOption> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/worksheet/blocks/{blockId}/answer_options',
            path: {
                'blockId': blockId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Answer Option
     * @returns void
     * @throws ApiError
     */
    public deleteAnswerOption({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/worksheet/answer_options/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Answer Option
     * @returns WorksheetAnswerOption successful
     * @throws ApiError
     */
    public updateAnswerOption({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            answerOption?: WorksheetAnswerOptionInput;
        },
    }): CancelablePromise<WorksheetAnswerOption> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/worksheet/answer_options/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Archive Worksheet Block
     * @returns void
     * @throws ApiError
     */
    public archiveWorksheetBlock({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/worksheet/blocks/{id}/archive',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Worksheet Block
     * @returns WorksheetBlock successful
     * @throws ApiError
     */
    public createWorksheetBlock({
        worksheetId,
        requestBody,
    }: {
        worksheetId: string,
        requestBody: {
            response?: WorksheetBlockInput;
        },
    }): CancelablePromise<WorksheetBlock> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/worksheet/worksheets/{worksheetId}/blocks',
            path: {
                'worksheetId': worksheetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * List of Worksheet Blocks
     * @returns WorksheetBlocks successful
     * @throws ApiError
     */
    public listWorksheetBlocks({
        worksheetId,
        question,
        page = 1,
        limit = 10,
    }: {
        worksheetId: string,
        question?: boolean,
        page?: number,
        limit?: number,
    }): CancelablePromise<WorksheetBlocks> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/worksheet/worksheets/{worksheetId}/blocks',
            path: {
                'worksheetId': worksheetId,
            },
            query: {
                'question': question,
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Block
     * @returns void
     * @throws ApiError
     */
    public deleteWorksheetBlock({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/worksheet/blocks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Worksheet Block Details
     * @returns WorksheetBlock successful
     * @throws ApiError
     */
    public getWorksheetBlock({
        id,
    }: {
        id: string,
    }): CancelablePromise<WorksheetBlock> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/worksheet/blocks/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Worksheet Block
     * @returns WorksheetBlock successful
     * @throws ApiError
     */
    public updateWorksheetBlock({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            response?: WorksheetBlockInput;
        },
    }): CancelablePromise<WorksheetBlock> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/worksheet/blocks/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Move Worksheet Block
     * @returns WorksheetBlock successful
     * @throws ApiError
     */
    public moveWorksheetBlock({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: number,
    }): CancelablePromise<WorksheetBlock> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/api/worksheet/blocks/{id}/move',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Block Solution
     * @returns WorksheetBlockSolution successful
     * @throws ApiError
     */
    public createWorksheetBlockSolution({
        blockId,
        requestBody,
    }: {
        blockId: string,
        requestBody: {
            solution?: WorksheetBlockSolutionInput;
        },
    }): CancelablePromise<WorksheetBlockSolution> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/worksheet/blocks/{blockId}/solutions',
            path: {
                'blockId': blockId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Delete Block Solution
     * @returns void
     * @throws ApiError
     */
    public deleteWorksheetBlockSolution({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/worksheet/solutions/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Block Solution
     * @returns WorksheetBlockSolution successful
     * @throws ApiError
     */
    public updateWorksheetBlockSolution({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            solution?: WorksheetBlockSolutionInput;
        },
    }): CancelablePromise<WorksheetBlockSolution> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/worksheet/solutions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Worksheet Response Feedback
     * @returns WorksheetResponseFeedback successful
     * @throws ApiError
     */
    public createWorksheetFeedback({
        responseId,
        requestBody,
    }: {
        responseId: string,
        requestBody: {
            feedback?: WorksheetResponseFeedbackInput;
        },
    }): CancelablePromise<WorksheetResponseFeedback> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/worksheet/responses/{responseId}/feedback',
            path: {
                'responseId': responseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `parameter missing`,
                401: `unauthorized`,
                404: `not found`,
                422: `UnexpectedResponse`,
            },
        });
    }
    /**
     * Delete Response Feedback
     * @returns void
     * @throws ApiError
     */
    public deleteWorksheetFeedback({
        responseId,
    }: {
        responseId: string,
    }): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/api/worksheet/responses/{responseId}/feedback',
            path: {
                'responseId': responseId,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Worksheet Feedback Details
     * @returns WorksheetResponseFeedback successful
     * @throws ApiError
     */
    public getWorksheetFeedback({
        responseId,
    }: {
        responseId: string,
    }): CancelablePromise<WorksheetResponseFeedback> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/worksheet/responses/{responseId}/feedback',
            path: {
                'responseId': responseId,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Worksheet Response Feedback
     * @returns WorksheetResponseFeedback successful
     * @throws ApiError
     */
    public updateWorksheetFeedback({
        responseId,
        requestBody,
    }: {
        responseId: string,
        requestBody: {
            feedback?: WorksheetResponseFeedbackInput;
        },
    }): CancelablePromise<WorksheetResponseFeedback> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/worksheet/responses/{responseId}/feedback',
            path: {
                'responseId': responseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Response
     * @returns WorksheetResponse successful
     * @throws ApiError
     */
    public createWorksheetResponse({
        worksheetId,
        requestBody,
    }: {
        worksheetId: string,
        requestBody: {
            response?: WorksheetResponseInput;
        },
    }): CancelablePromise<WorksheetResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/worksheet/worksheets/{worksheetId}/responses',
            path: {
                'worksheetId': worksheetId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `parameter missing`,
                401: `unauthorized`,
                404: `not found`,
                422: `UnexpectedResponse`,
            },
        });
    }
    /**
     * List of Responses
     * @returns WorksheetResponses successful
     * @throws ApiError
     */
    public listWorksheetResponses({
        worksheetId,
        page = 1,
        limit = 10,
    }: {
        worksheetId: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<WorksheetResponses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/worksheet/worksheets/{worksheetId}/responses',
            path: {
                'worksheetId': worksheetId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Response Details
     * @returns WorksheetResponse successful
     * @throws ApiError
     */
    public getWorksheetResponse({
        id,
        xAccessToken,
    }: {
        id: string,
        xAccessToken?: string,
    }): CancelablePromise<WorksheetResponse> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/worksheet/responses/{id}',
            path: {
                'id': id,
            },
            headers: {
                'X-Access-Token': xAccessToken,
            },
            errors: {
                403: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Create Worksheet
     * @returns Worksheet successful
     * @throws ApiError
     */
    public createWorksheet({
        requestBody,
    }: {
        requestBody: {
            response?: WorksheetInput;
        },
    }): CancelablePromise<Worksheet> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/worksheet/worksheets',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                422: `parameter missing`,
            },
        });
    }
    /**
     * Worksheet Details
     * @returns Worksheet successful
     * @throws ApiError
     */
    public getWorksheet({
        id,
        token,
        publicToken,
        password,
    }: {
        id: string,
        token?: string,
        publicToken?: string,
        password?: string,
    }): CancelablePromise<Worksheet> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/worksheet/worksheets/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'public_token': publicToken,
                'password': password,
            },
            errors: {
                400: `bad request`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Worksheet
     * @returns Worksheet successful
     * @throws ApiError
     */
    public updateWorksheet({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            response?: WorksheetInput;
        },
    }): CancelablePromise<Worksheet> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/worksheet/worksheets/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
