<script lang="ts">
export default {
  name: "BaseButton",
};
</script>

<script setup lang="ts">
import { type RouterLinkProps } from "vue-router";

export type Props = {
  disabled?: boolean;
  to?: RouterLinkProps["to"];
  kind?:
    | "primary"
    | "secondary"
    | "success"
    | "danger"
    | "warning"
    | "info"
    | "light"
    | "dark"
    | "link"
    | "naked"; // material search forms;
  variant?: "dark" | "light" | "primary"; // used for links
  rounded?: boolean;
  circle?: boolean;
  size?: "sm" | "md" | "lg";
  weight?: "medium" | "normal" | "semibold" | "bold";
  type?: "button" | "submit" | "reset" | "";
  ariaLabel?: string;
  inline?: boolean;
  underline?: boolean; // used for links
  noPadding?: boolean; // used for links
};

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  to: undefined,
  kind: "primary",
  rounded: false,
  circle: false,
  size: "md",
  weight: "normal",
  type: "",
  variant: "primary",
  ariaLabel: "",
  inline: false,
  underline: false,
  noPadding: false,
});

const classes = computed(() => {
  const classNames = [];
  if (props.kind) {
    classNames.push(`btn-${props.kind}`);
  }

  if (props.size) {
    classNames.push(`btn-${props.size}`);
  }

  if (props.weight) {
    classNames.push(`fw-${props.weight}`);
  }

  if (props.disabled) {
    classNames.push("opacity-50 cursor-not-allowed");
  }

  if (props.rounded) {
    classNames.push("btn-rounded");
  }

  if (props.circle) {
    classNames.push("btn-circle");
  }

  if (props.kind === "link" && !!props.variant && props.variant !== "primary") {
    // without suffix, ".btn-link" defaults to brand-tools/primary color
    classNames.push(`btn-link--${props.variant}`);
  }

  return classNames.join(" ");
});

const componentProps = computed(() => {
  const properties = {
    class: classes.value,
    disabled: props.disabled,
    "aria-label": props.ariaLabel,
  };

  if (props.to) {
    return {
      ...properties,
      to: props.to,
    };
  }

  if (props.type) {
    return {
      ...properties,
      type: props.type,
    };
  }

  return properties;
});

const componentType = computed(() => {
  if (props.to) {
    return "router-link";
  }

  return "button";
});

const cssClasses = computed(() => {
  const classes = ["btn"];

  if (props.inline) {
    classes.push("btn-inline-flex");
  } else {
    classes.push("btn-flex");
  }

  if (props.underline) {
    classes.push("btn-underline");
  }

  if (props.noPadding) {
    classes.push("btn-no-padding");
  }

  return classes.join(" ");
});
</script>

<template>
  <Component :is="componentType" v-bind="componentProps" :class="cssClasses">
    <slot></slot>
  </Component>
</template>
