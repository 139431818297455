import merge from "lodash.merge";

type MessageTranslations = {
  [key: string]: string | MessageTranslations; // Allow nested structures
};

type Locales = "de" | "en";
type WithLocale = {
  en: MessageTranslations;
  de: MessageTranslations;
};

export function loadLocaleMessages() {
  const messages: WithLocale = {
    en: {},
    de: {},
  };

  const localeFiles = import.meta.glob<Record<string, MessageTranslations>>(
    "./**/*.json",
    {
      eager: true,
    }
  );
  Object.keys(localeFiles).forEach((key) => {
    const matched = key.match(/\.\/([a-z0-9-_]+)\/([a-z0-9-_]+)\.json$/i);
    if (matched && matched.length > 2) {
      const locale = matched[1] as Locales;
      const fileName = matched[2];
      if (!messages[locale]) {
        messages[locale] = {};
      }

      const attrs = localeFiles[key].default[locale] as MessageTranslations;

      if (fileName === "global") {
        // global.json is a special file that merges its content with all other files
        // useful for shared translations without namespace prefix
        merge(messages[locale], attrs);
      } else {
        if (!messages[locale][fileName]) {
          messages[locale][fileName] = {};
        }

        merge(messages[locale][fileName], attrs[fileName]);
      }
    }
  });

  return messages;
}
