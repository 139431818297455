/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PupilFeedbackAssignment } from '../models/PupilFeedbackAssignment';
import type { PupilFeedbackAssignmentInput } from '../models/PupilFeedbackAssignmentInput';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PupilFeedbackAssignmentsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Assignment Details
     * @returns PupilFeedbackAssignment successful
     * @throws ApiError
     */
    public getAssignment({
        id,
        token,
        publicToken,
        password,
    }: {
        id: string,
        token?: string,
        publicToken?: string,
        password?: string,
    }): CancelablePromise<PupilFeedbackAssignment> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupil_feedback/assignments/{id}',
            path: {
                'id': id,
            },
            query: {
                'token': token,
                'public_token': publicToken,
                'password': password,
            },
            errors: {
                400: `bad request`,
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
    /**
     * Update Assignment
     * @returns PupilFeedbackAssignment successful
     * @throws ApiError
     */
    public updateAssignment({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            assignment?: PupilFeedbackAssignmentInput;
        },
    }): CancelablePromise<PupilFeedbackAssignment> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/pupil_feedback/assignments/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `unauthorized`,
                403: `forbidden`,
                404: `not found`,
            },
        });
    }
}
