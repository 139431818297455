/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PupilFeedbackFeedback } from '../models/PupilFeedbackFeedback';
import type { PupilFeedbackFeedbackInput } from '../models/PupilFeedbackFeedbackInput';
import type { PupilFeedbackResponse } from '../models/PupilFeedbackResponse';
import type { PupilFeedbackResponseInput } from '../models/PupilFeedbackResponseInput';
import type { PupilFeedbackResponses } from '../models/PupilFeedbackResponses';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PupilFeedbackResponsesService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a Pupil Feedback Feedback
     * @returns PupilFeedbackFeedback created
     * @throws ApiError
     */
    public createFeedback({
        responseId,
        requestBody,
    }: {
        responseId: string,
        requestBody: {
            feedback?: PupilFeedbackFeedbackInput;
        },
    }): CancelablePromise<PupilFeedbackFeedback> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/pupil_feedback/responses/{responseId}/feedbacks',
            path: {
                'responseId': responseId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Create a Pupil Feedback Response
     * @returns PupilFeedbackResponse created
     * @throws ApiError
     */
    public createResponse({
        assignmentId,
        requestBody,
    }: {
        assignmentId: string,
        requestBody: {
            response?: PupilFeedbackResponseInput;
        },
    }): CancelablePromise<PupilFeedbackResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/pupil_feedback/assignments/{assignmentId}/responses',
            path: {
                'assignmentId': assignmentId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * List of Pupil Feedback Responses
     * @returns PupilFeedbackResponses successful
     * @throws ApiError
     */
    public listResponses({
        assignmentId,
        page = 1,
        limit = 10,
    }: {
        assignmentId: string,
        page?: number,
        limit?: number,
    }): CancelablePromise<PupilFeedbackResponses> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupil_feedback/assignments/{assignmentId}/responses',
            path: {
                'assignmentId': assignmentId,
            },
            query: {
                'page': page,
                'limit': limit,
            },
            errors: {
                401: `unauthorized`,
                404: `not found`,
            },
        });
    }
    /**
     * Get a Pupil Feedback Response
     * @returns any successful
     * @throws ApiError
     */
    public getResponse({
        id,
    }: {
        id: string,
    }): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/pupil_feedback/responses/{id}',
            path: {
                'id': id,
            },
            errors: {
                404: `not found`,
            },
        });
    }
    /**
     * Update a Pupil Feedback Response
     * @returns PupilFeedbackResponse updated
     * @throws ApiError
     */
    public updateResponse({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: {
            response?: PupilFeedbackResponseInput;
        },
    }): CancelablePromise<PupilFeedbackResponse> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/api/pupil_feedback/responses/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
